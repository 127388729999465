<template>
  <div class="px-1 pb-2">
    <v-card class="mx-auto">
      <v-container fluid>
        <v-row class="">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="curUser.fullname"
              label="Fullname"
              filled
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-model="curUser.username"
              label="Username"
              filled
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="curUser.passwd"
              label="Password"
              filled
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            class="my-3"
            color="secondary"
            :loading="loading"
            @click="saveChanges"
            >Save Changes</v-btn
          >
        </v-row>
      </v-container>
    </v-card>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { mapState } from "vuex";
import { snackMixin } from "@/mixins";
export default {
  name: "Nadmin",
  mixins: [snackMixin],
  data() {
    return {
      loading: false,
      disabled: false,
    };
  },
  computed: {
    ...mapState(["curUser"]),
  },
  methods: {
    async saveChanges() {
      this.loading = true;
      try {
        let updateUser = {
          id: this.curUser.id,
          fullname: this.curUser.fullname,
          username: this.curUser.username,
          passwd: this.curUser.passwd,
          type: "admin",
        };
        const res = await apiClient.put("/admins", updateUser);
        if (res.data.ok) {
          this.displayMsg("Changes saved successfully");
        } else {
          this.displayMsg("Not successfully added", "error");
        }
      } catch (err) {
        this.displayMsg(err);
      }
      this.loading = false;
    },
  },
};
</script>
